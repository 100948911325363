import React from "react";
// @mui/*
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import { createStyles, makeStyles } from "@mui/styles"
import { Button } from "@mui/material";
import { SxProps } from "@mui/system";

// @types
const dropdownStyles: SxProps = {
  position: 'absolute',
  top: 40,
  right: 0,
  left: 0,
  border: '1px solid #1976d2',
  borderRadius: 6,
  padding: "12px",
  bgcolor: 'background.paper',
  width: "fit-content"
};


interface IButtonProps {
  // children should be an icon
  children: React.ReactNode;
  list: React.ReactNode[];
}

// @component
const ButtonDropDown: React.FC<IButtonProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: 'relative' }}>
        <Button type="button" onClick={handleClick}>
          {props.children}
        </Button>
        {open ? (
          <Box sx={dropdownStyles}>
            {props.list.map((item, index) => <div key={index}>{item}</div>)}
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  )
}

export default ButtonDropDown;