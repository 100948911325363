import React from "react";
// @router
import { RouteComponentProps, Link } from "@reach/router";
// @mui
import { makeStyles } from "@mui/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
// components
import PostCard from "../components/PostCard";
import { usePosts } from "../states/usePosts";
import ButtonDropDown from "../components/ButtonDropDown";
import Option from "../components/Option";

const useStyles = makeStyles({
  topicsContainer: {
    display: "flex",
    alignItems: "center",
  },
  topicsText: {
    marginRight: 15,
  },
  topic: {
    margin: 10,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    // columnGap: 30,
    width: "60%",
    margin: "auto",
    justifyContent: "space-between",
  },
  cardContainer: {
    width: "60%",
    margin: "20px auto",
    boxShadow: "none",
  },
});

const staticTopics = ["Sports", "Software", "Cars"];

const Posts = (props: RouteComponentProps) => {
  const classes = useStyles();
  const postsHooks = usePosts();

  // options for filter (string)
  const options = ["Sort by ascending upvote", "Sort by descending upvote"];

  // list options components
  const filterOptions = options.map((op, index) => {
    return (
      <Option
        key={index}
        option={op}
        handleSelect={() => postsHooks.handleSelect(index)}
        active={postsHooks.choice === index}
      />
    );
  });

  return (
    <div>
      <div className={classes.topicsContainer}>
        <h1 className={classes.topicsText}>YOUR TOPICS</h1>
        {staticTopics.map((topic, index) => (
          <div key={index} className={classes.topic}>
            {topic}
          </div>
        ))}
      </div>
      <section className={classes.headerContainer}>
        <h1>Posts</h1>
        <ButtonDropDown list={filterOptions}>
          <FilterListIcon />
        </ButtonDropDown>
      </section>
      {postsHooks.posts.map((post) => (
        <div key={post.id} className={classes.cardContainer}>
          <PostCard post={post} />
        </div>
      ))}
    </div>
  );
};

export default Posts;
