import React from "react"
import { Router, RouteComponentProps, Link } from "@reach/router"
import Posts from './pages/posts'
import Post from './pages/post'
import { makeStyles } from "@mui/styles"


const useStyles = makeStyles({
  container: {
    margin: "85px 15px 0px 15px",
  }
})


function AppRoutes() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Router>
        <Posts path="/" />
        <Post path="/posts/:id" />
      </Router>
    </div>
  );
}

export default AppRoutes;