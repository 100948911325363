import React, { useEffect, useState } from "react";

export function usePosts() {
  const [posts, setPosts] = useState([]);
  const [choice, setChoice] = useState(-1);


  const getPosts = async () => {
    const resp = await fetch(
      "https://worker-api.minh160302.workers.dev/posts"
    );
    const postsResp = await resp.json();
    setPosts(postsResp);
  };

  const handleSelect = (value) => {
    if (value === choice) {
      setChoice(-1);
      value = -1;
    }
    else {
      setChoice(value);
    }

    filterByOption(value);
  }

  // ["Sort by ascending upvote", "Sort by descending upvote"];
  // fetch api after select filter options
  const filterByOption = async (option) => {
    // asc
    if (option == 0) {
      const resp = await fetch(
        "https://worker-api.minh160302.workers.dev/posts_asc"
      );
      const postsResp = await resp.json();
      setPosts(postsResp);
    }

    // desc
    else if (option == 1) {
      const resp = await fetch(
        "https://worker-api.minh160302.workers.dev/posts_desc"
      );
      const postsResp = await resp.json();
      setPosts(postsResp);
    }

    // no filter
    else if (option == -1) {
      getPosts();
    }

    // unknown cases(?)
    else {
      console.log("Unknown filter options!")
    }
  }


  useEffect(() => {
    getPosts();
  }, [])

  return { posts, choice, handleSelect, setPosts }
}