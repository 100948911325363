import React from "react"
import { Router, RouteComponentProps, Link } from "@reach/router"
import Posts from './pages/posts'
import Post from './pages/post'
import Header from "./components/Header";
import AppRoutes from "./app-routes";

function App() {
  return (
    <div>
      <Header />
      <AppRoutes />
    </div>
  );
}

export default App;