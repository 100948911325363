import React, { ReactNode } from "react"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles(() =>
  createStyles({
    option: {
      width: 200,
      padding: "12px 5px",
      "&:hover": {
        cursor: "pointer",
        opacity: 0.6
      }
    },
    active: {
      color: "#1976d2"
    }
  })
)

interface IOptionProps {
  option: ReactNode;
  active: boolean;
  handleSelect: (value: number) => void;
}

const Option: React.FC<IOptionProps> = (props) => {
  const { option, active, handleSelect } = props;
  const classes = useStyles();

  return <div className={classes.option} onClick={handleSelect}>
    <div className={active === true ? classes.active : ""}>{option}</div>
  </div>;
};

export default Option;