import React from "react";
// @reach/router
import { Link } from "@reach/router";
// @mui
import { makeStyles } from "@mui/styles";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
import TextField from "@mui/material/TextField";
import { Button, IconButton } from "@mui/material";
// components
import { usePost } from "../states/usePost";
import PostCard from "../components/PostCard";


const useStyles = makeStyles({
  postContainer: {
    maxWidth: 1200,
    margin: "auto",
    display: "flex",
    columnGap: 30,
  },
  author: {
    display: "flex",
    alignItems: "center",
    columnGap: 15,
  },
  upvoteWrapper: {
    display: "flex",
  },
  upvoteContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  upvoteNumber: {
    padding: 8,
  },
  commentHeader: {
    margin: "50px 0px 15px 0px",
  },
  comment: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  commentContainer: {
    marginBottom: 20,
  },
});

const Post = ({ id }) => {
  const classes = useStyles();
  const postHook = usePost(id);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (!Object.keys(postHook.post).length) return <div />;

  return (
    <div className={classes.postContainer}>
      {/* left part */}
      <section>
        <div className={classes.author}>
          <h1>Author</h1>
          <span>
            <em>{postHook.post.username}</em>
          </span>
        </div>

        <div className={classes.upvoteWrapper}>
          <div>
            <div className={classes.upvoteContainer}>
              <IconButton onClick={postHook.increment}>
                <ThumbUpAltOutlinedIcon />
              </IconButton>
            </div>
            <div className={classes.upvoteContainer}>
              <IconButton onClick={postHook.decrement}>
                <ThumbDownAltOutlinedIcon />
              </IconButton>
            </div>
            <span className={classes.upvoteNumber}>{postHook.upvote}</span>
          </div>

          <div className={classes.upvoteContainer}>
            <IconButton>
              <InsertCommentOutlinedIcon />
            </IconButton>
          </div>
        </div>
      </section>

      {/* right part */}
      <section>
        <h1>{postHook.post.title}</h1>
        <span>
          <em>{postHook.post.description}</em>
        </span>
        <p>{postHook.post.content}</p>
        <p>
          {/* <em>Published {new Date(post.published_at).toLocaleString()}</em> */}
        </p>
        <p>
          <Link to="/">Go back</Link>
        </p>

        {/* comment section */}
        <h3 className={classes.commentHeader}>Comments</h3>
        <div>
          <TextField
            id="filled-multiline-static"
            label={
              <span>
                contributed as{" "}
                <strong>
                  <em>Guest &nbsp;</em>
                </strong>
              </span>
            }
            multiline
            rows={4}
            placeholder="Write your thoughts here!"
            variant="filled"
            value={postHook.comment}
            onChange={postHook.handleChange}
            className={classes.comment}
          />
          <Button onClick={postHook.handleComment}>Save</Button>
          <Button color="error" onClick={postHook.handleCancel}>Cancel</Button>
        </div>

        {/* existed comment */}
        <div>
          {postHook.post.comments.map((com, index) => {
            const date = new Date(com.createdAt);
            return (
              <div className={classes.commentContainer} key={index}>
                <TextField
                  id="outlined-multiline-static"
                  label={
                    <span>
                      contributed as{" "}
                      <strong>
                        <em>Guest &nbsp;</em>
                      </strong>
                    </span>
                  }
                  multiline
                  value={com.content}
                  variant="filled"
                  classes={{
                    root: classes.comment,
                  }}
                  disabled={true}
                />
                <div>
                  <em>
                    Comment at: {monthNames[date.getMonth()]} {date.getDate()},{" "}
                    {date.getHours()}:{date.getMinutes()}
                  </em>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Post;
