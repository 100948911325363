import React, { useEffect, useState } from "react";
import { usePosts } from "./usePosts";

export function useHeader() {
  const postsHooks = usePosts()
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState({
    username: "",
    title: "",
    upvote: 0,
    description: "",
    comments: [],
    content: ""
  })

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setInputValue({
      username: "",
      title: "",
      upvote: 0,
      description: "",
      comments: [],
      content: ""
    })
  }

  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async () => {
    const resp = await fetch(
      "https://worker-api.minh160302.workers.dev/posts"
    );
    const postsResp = await resp.json();

    const newPost = {
      id: `${postsResp.length + 1}`,
      ...inputValue
    }

    await fetch(`https://worker-api.minh160302.workers.dev/posts`, {
      method: "POST",
      body: JSON.stringify(newPost),
    })
      .then(() => {
        handleClose()
      })
      .catch(e => {
        console.log(e)
      })

    window.location.reload()
  }

  return { open, handleOpen, handleClose, inputValue, handleChange, handleSubmit };
}
