import React from "react";
import { RouteComponentProps, Link } from "@reach/router";
// @mui/material
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { makeStyles } from "@mui/styles"


const useStyles = makeStyles({
  cardWrapper: {
    display: 'flex',
    width: "fit-content",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    boxShadow: "none !important",
    // border: "1px solid black"
    "&:hover": {
      cursor: "pointer"
    }
  },
  cardContent: {
    width: "100%"
  },
  username: {
    padding: 8
  },
  link: { 
    color: "#1976d2",
    "&:visited": {
      color: "#1976d2"
    }
  }
})


const PostCard = (props) => {
  const { post } = props;
  const classes = useStyles();

  return (
    <Card className={classes.cardWrapper}>
      <CardContent className={classes.cardContent}>
        <Link to={`/posts/${post.id}`} style={{textDecoration: "none"}} className={classes.link}>
          <Typography component="div" variant="h5" className={classes.title}>
            {post.title}
          </Typography>
        </Link>
        <Typography className={classes.username} component="div" variant="h7">
          by <span><strong>{post.username}</strong></span>
        </Typography>
        <Typography variant="subtitle1">
          Upvote: {post.upvote}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" component="div">
          {post.description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default PostCard;