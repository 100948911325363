import React, { useEffect, useState } from "react";

export function usePost(postId) {
  const [post, setPost] = useState({});
  const [upvote, setUpvote] = useState(0);
  const [comment, setComment] = useState("")
  const [comments, setComments] = useState([])

  const getPost = async () => {
    const resp = await fetch(
      `https://worker-api.minh160302.workers.dev/posts/${postId}`
    );
    const postResp = await resp.json();
    setPost(postResp);
    setUpvote(postResp.upvote);
  };

  // change upvote value
  const increment = async () => {
    setUpvote(upvote + 1);

    post.upvote = upvote + 1;
    await fetch(`https://worker-api.minh160302.workers.dev/posts`, {
      method: "POST",
      body: JSON.stringify(post),
    })
      .then((res) => {
        console.log("response: ", res);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  };

  const decrement = async () => {
    setUpvote(upvote - 1);
    post.upvote = upvote - 1;
    await fetch(`https://worker-api.minh160302.workers.dev/posts`, {
      method: "POST",
      body: JSON.stringify(post),
    })
      .then((res) => {
        console.log("response: ", res);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  };


  // comment
  const handleChange = (event) => {
    setComment(event.target.value);
  }

  const handleComment = async () => {
    const newComment = {
      content: comment,
      createdAt: Date.now()
    }

    post.comments.push(newComment);
    await fetch(`https://worker-api.minh160302.workers.dev/posts`, {
      method: "POST",
      body: JSON.stringify(post),
    })
      .then((res) => {
        setComments(post);
        setComment("")
      })
      .then(() => {
        getPost();
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }

  const handleCancel = () => {
    setComment("")
  }

  useEffect(() => {
    getPost();
  }, [postId]);

  return { post, increment, decrement, upvote, 
    handleComment, comment, handleChange, handleCancel
  };
}
