import React from "react";
//  @mui
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  AppBar, IconButton, Modal, Toolbar, Box,
  Typography, TextareaAutosize, TextField, Button
} from "@mui/material"
import { makeStyles } from "@mui/styles"
// @components
import { useHeader } from "../states/useHeader";


const useStyles = makeStyles({
  header: {
    position: "fixed",
    zIndex: 10
  },
  logo: {
    fontSize: 20,
    color: "#ffffff",
    display: "flex",
    justifyContent: "space-between"
  },
  authorInput: {
    margin: "15px 0px !important",
    width: "100%"
  }
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 6,
  boxShadow: 24,
  p: 4,
};

export default function Header() {
  const classes = useStyles();
  const headerHooks = useHeader();

  const displayDesktop = () => {
    return <Toolbar className={classes.logo}>
      <h3>Minh Nguyen' Medium</h3>
      {/* open modal */}
      <IconButton onClick={headerHooks.handleOpen}>
        <AddCircleOutlineIcon style={{ fill: "#fff" }} />
      </IconButton>
    </Toolbar>;
  };

  return (
    <header className={classes.header}>
      <AppBar>{displayDesktop()}</AppBar>
      <Modal
        open={headerHooks.open}
        onClose={headerHooks.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create post
          </Typography>
          <TextField
            label="Author"
            type="text"
            classes={{
              root: classes.authorInput
            }}
            required
            value={headerHooks.inputValue.username}
            name="username"
            onChange={headerHooks.handleChange}
          />
          <TextField
            label="Title"
            type="text"
            classes={{
              root: classes.authorInput
            }}
            required
            value={headerHooks.inputValue.title}
            name="title"
            onChange={headerHooks.handleChange}
          />
          <TextField
            label="Description"
            type="text"
            classes={{
              root: classes.authorInput
            }}
            required
            value={headerHooks.inputValue.description}
            name="description"
            onChange={headerHooks.handleChange}
          />
          <TextareaAutosize
            minRows={5}
            placeholder="Content goes here..."
            style={{ width: "100%" }}
            value={headerHooks.inputValue.content}
            name="content"
            onChange={headerHooks.handleChange}
          />
          <div>
            <Button onClick={headerHooks.handleSubmit}>Submit</Button>
            <Button onClick={headerHooks.handleClose} color="error">Cancel</Button>
          </div>
        </Box>
      </Modal>
    </header>
  );
}